import React from "react";
import Snowfall from "react-snowfall";
import LandingPage from "./pages/LandingPage";

function App() {
  return (
    <div className="relative">
      {/* Globaler Snowfall Effekt */}
      <Snowfall color="white" snowflakeCount={200} />
      <LandingPage />
    </div>
  );
}

export default App;
