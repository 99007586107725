import { useState, useEffect } from "react";

const Home = () => {
  const [timeLeft, setTimeLeft] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });

  useEffect(() => {
    const calculateTimeLeft = () => {
      const now = new Date();
      const christmas = new Date(`December 25, ${now.getFullYear()} 00:00:00`);
      const difference = christmas - now;

      if (difference > 0) {
        setTimeLeft({
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((difference / (1000 * 60)) % 60),
          seconds: Math.floor((difference / 1000) % 60),
        });
      } else {
        setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
      }
    };

    const timer = setInterval(calculateTimeLeft, 1000);
    return () => clearInterval(timer);
  }, []);

  const giftFacts = [
    { title: "The First Memecoin", text: "Unwrap the first-ever memecoin with holiday vibes!" },
    { title: "The Ultimate Festive Token", text: "Crypto Claus $HOHO is your ticket to blockchain fun!" },
    { title: "HODL for Joy", text: "Hold $HOHO because Santa's sleigh is powered by HODLers!" },
    { title: "Crypto Claus Origins", text: "Crypto Claus is Santa's cool cousin, bringing blockchain magic to Christmas!" },
  ];

  return (
    <div
      className="min-h-screen flex flex-col items-center justify-center px-4"
      style={{
        backgroundImage: `url('/background.jpg')`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      {/* Header */}
      <div className="text-center mt-6">
        <h1
          className="text-4xl md:text-6xl font-bold text-red-600 drop-shadow-lg"
          style={{
            textShadow: "2px 2px 5px rgba(0, 0, 0, 0.5)",
          }}
        >
          Crypto Claus
        </h1>
        <p
          className="text-xl md:text-2xl font-semibold"
          style={{
            color: "#FFD700", // Golden Yellow
            textShadow: "1px 1px 3px rgba(0, 0, 0, 0.5)",
          }}
        >
          $HOHO - The jolliest memecoin of the season!
        </p>
      </div>

      {/* Social Buttons */}
      <div className="mt-6 flex space-x-4 justify-center">
        {/* X Button */}
        <a
          href="https://x.com/CryptoClausCoin"
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center px-4 py-2 bg-gray-800 text-white rounded-lg shadow-md hover:bg-gray-700 transition-all"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 24 24"
            className="w-5 h-5 mr-2"
          >
            <path d="M21.99 3.99h-4.2l-3.8 5.47-3.83-5.47H5.99l5.74 8.17-6.27 8.84h4.27l4.18-5.91 4.16 5.91h4.27l-6.24-8.84 5.79-8.17z" />
          </svg>
          X
        </a>
        
        {/* Telegram Button */}
        <a
          href="https://t.me/cryptoclaushoho"
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center px-4 py-2 bg-gray-800 text-white rounded-lg shadow-md hover:bg-gray-700 transition-all"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 24 24"
            className="w-5 h-5 mr-2"
          >
            <path d="M9.368 16.915 8.975 21c.473 0 .683-.207.927-.454l2.221-2.12 4.612 3.373c.84.462 1.433.219 1.644-.774l2.975-13.805h.001c.245-1.081-.399-1.526-1.216-1.257L3.03 10.468c-1.061.408-1.042.99-.18 1.247l3.651 1.143 8.452-5.322c.398-.245.76-.114.462.158z" />
          </svg>
          Telegram
        </a>
      </div>

      {/* Countdown */}
      <div className="mt-8 text-center">
        <h2
          className="text-3xl md:text-4xl font-semibold drop-shadow-lg"
          style={{
            color: "#ff0000",
            textShadow: "2px 2px 5px rgba(0, 0, 0, 0.7)",
          }}
        >
          Countdown to Christmas
        </h2>
        <div className="flex justify-center space-x-6 mt-4 text-2xl md:text-4xl font-bold text-white">
          {Object.entries(timeLeft).map(([unit, value], index) => (
            <div
              key={index}
              className="bg-red-600 text-white rounded-lg p-4 shadow-lg text-center"
            >
              <span>{value}</span>
              <div className="text-sm md:text-base mt-2 capitalize">{unit}</div>
            </div>
          ))}
        </div>
      </div>

      {/* Gift Boxes */}
      <div className="mt-12 grid grid-cols-2 md:grid-cols-4 gap-8 px-4">
        {giftFacts.map((fact, index) => (
          <div
            key={index}
            className="relative bg-gradient-to-br from-red-500 to-red-700 rounded-xl shadow-lg hover:scale-105 transition-transform duration-300 cursor-pointer"
            style={{ height: "220px", width: "160px" }}
          >
            {/* Schleifen-Design */}
            <div className="absolute inset-0 flex justify-center items-center">
              {/* Horizontale Schleife */}
              <div className="absolute bg-yellow-400 h-4 w-full top-1/2 transform -translate-y-1/2 shadow-md"></div>
              {/* Vertikale Schleife */}
              <div className="absolute bg-yellow-400 w-4 h-full left-1/2 transform -translate-x-1/2 shadow-md"></div>
            </div>

            {/* Hover-Inhalt */}
            <div className="absolute inset-0 flex flex-col items-center justify-center bg-red-800 text-white font-bold text-center opacity-0 hover:opacity-100 transition-opacity duration-300 p-4 rounded-xl z-20">
              <h3 className="text-yellow-400 text-lg mb-2">{fact.title}</h3>
              <p className="text-sm md:text-base">{fact.text}</p>
            </div>

            {/* Geschenk-Dekoration */}
            <div className="absolute inset-0 border-4 border-yellow-400 rounded-xl"></div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Home;
